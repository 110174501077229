import { Features } from '@/utils/enums'

export default{
    computed: {
        sortedRepartitionList(){

            return this.$_.sortBy(this.$parent.repartitionList.filter(r => r.class == this.studentClass.id), function(obj) {
                
                return parseInt(obj.idx, 10)

            })

        },
        speciality_1_Items(){

            if (this.$parent.classChoiceCourses) return this.$parent.classChoiceCourses.filter(cc => cc.feature == Features.SPECIALITY_1.id)
            else return []

        },
        speciality_2_Items(){

            if (this.$parent.classChoiceCourses) return this.$parent.classChoiceCourses.filter(cc => cc.feature == Features.SPECIALITY_2.id)
            else return []

        },
        reportBodySections(){

            let idList = [], courseSections = []

            if (this.sortedRepartitionList) {
            
                this.sortedRepartitionList.forEach(element => {
                
                    if (!idList.includes(element.section)) idList.push(element.section)

                })

                idList.forEach(id => {

                    let section = this.$parent.sections.find(s => s.id == id)

                    courseSections.push(section)

                })

            }

            return courseSections

        },
        reportSpecsBodySections(){

            let idList = [], courseSections = []

            if (this.sortedRepartitionList) {
            
                this.sortedRepartitionList.forEach(element => {
                
                    if (!idList.includes(element.section)) idList.push(element.section)

                })
                
                if (idList.length > 0) {
                    let section = this.$parent.sections.find(s => s.id == idList[0])
                    courseSections.push(section)
                    courseSections.push({ name: 'Matières de spécialité' })
                }

            }

            return courseSections

        },
        currentYearCuttingItem(){            

            return this.$parent.getYearCutting(this.$parent.currentYearCutting)

        },
        firstSemMoy(){
            let result = this.$parent.yearCuttingResults.find(r => (
                r.code == this.$parent.YearSemesters.FIRST_SEMESTER.id && r.student == this.student.id && r.schoolyear == this.$parent.schoolyear
            ))

            return result ? result.average : 0
        },
        firstTermMoy(){
            let result = this.$parent.yearCuttingResults.find(r => (
                r.code == this.$parent.YearTerms.FIRST_TERM.id && r.student == this.student.id && r.schoolyear == this.$parent.schoolyear
            ))

            return result ? result.average : 0
        },
        secondTermMoy(){
            let result = this.$parent.yearCuttingResults.find(r => (
                r.code == this.$parent.YearTerms.SECOND_TERM.id && r.student == this.student.id && r.schoolyear == this.$parent.schoolyear
            ))

            return result ? result.average : 0
        },
        currentMoyLabel(){

            let label = ''

            if (!this.currentYearCuttingItem) return label

            if (this.$parent.isYearCuttingTerm){
                
                switch(parseInt(this.currentYearCuttingItem.code)){
                    case this.$parent.YearTerms.FIRST_TERM.id:
                        label = 'Trim1'      
                        break

                    case this.$parent.YearTerms.SECOND_TERM.id:
                        label = ''      
                        break

                    case this.$parent.YearTerms.THIRD_TERM.id:
                        label = ''      
                        break
                }

            }else{
                
                switch (parseInt(this.currentYearCuttingItem.code)) {
                    case this.$parent.YearSemesters.FIRST_SEMESTER.id:
                        label = 'Sem1'      
                        break
                        
                    case this.$parent.YearSemesters.SECOND_SEMESTER.id:
                        label = ''      
                        break
                }

            }
            
            return `Moyenne ${label}`

        }
    },
    methods: {
        courseNameFontSize(courseName){
            if (courseName.length <= 13) return 12
            else if (courseName.length > 13 && courseName.length <= 18) return 10
            else return 8
        },
        getEvaluationTypeTypeFromEvaluation(id) {

            let evaluation = this.$parent.evaluations.find(ev => ev.id == id)

            return this.$parent.getEvaluationTypeType(evaluation ? evaluation.type : 0)            

        }
    },
}