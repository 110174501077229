<template>
    
    <div>
        <template v-if="student && studentClass">                            

            <div class="report-header">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <div class="info-box standard-height">
                            <el-row>
                                <el-col :span="8">
                                    <img :src="$parent.getSchoolData($parent.SchoolData.LOGO.NAME).replace('http://localhost', APP_BASE_URL)" style="width:90%; max-height:100%" alt="logo">
                                </el-col>
                                <el-col :span="16">
                                    <el-row :gutter="10" class="text-center mb-10" style="color: blue">
                                        <el-col :span="24">
                                            <div style="font-size:22px; margin-bottom: 5px"><b>{{ $parent.getSchoolData($parent.SchoolData.NAME.NAME).toUpperCase() }}</b></div>
                                            <div style="font-size:14px; line-height: 1.5"><b>{{ $parent.getSchoolData($parent.SchoolData.DESCRIPTION.NAME).toUpperCase() }}</b></div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10" class="text-left mt-5" style="color: blue">
                                        <el-col :span="24" style="font-size:13px; line-height: 1.4">
                                            <div>{{ $parent.getSchoolData($parent.SchoolData.ADDRESS.NAME) }}</div>
                                            <div><b>Tel:</b> {{ $parent.getSchoolData($parent.SchoolData.PHONE.NAME) }} <br> {{ $parent.getSchoolData($parent.SchoolData.POSTAL_BANK.NAME) }}</div>
                                            <div><b>Site:</b> {{ $parent.getSchoolData($parent.SchoolData.WEBSITE.NAME) }}</div>
                                            <div><b>Email:</b> {{ $parent.getSchoolData($parent.SchoolData.EMAIL.NAME) }}</div>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>                        
                        </div>                        
                    </el-col>
                    <el-col :span="12">
                        <div class="info-box standard-height">
                            <el-row :gutter="10">
                                <el-col :span="8" style="font-size: 18px">
                                    <b class="underline">Elève</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 16px">
                                    Année scolaire&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{ schoolyearCode }}
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>Nom</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 17px">
                                    :&nbsp;<b>{{ student.lastname }}</b>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>Prénom(s)</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 17px">
                                    :&nbsp;<b>{{ student.firstname }}</b>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>Né le</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 17px">
                                    :&nbsp;<b>{{ student.birthday ? reformatDate(student.birthday, { outPattern: 'DD/MM/YYYY' }) : '' }}</b>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>Lieu de naissance</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 17px">
                                    :&nbsp;<b>{{ student.birthplace }}</b>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" style="margin-top: 20px; margin-bottom: 4px">
                                <el-col :span="12" style="font-size: 16px">
                                    <b>Classe :&nbsp;</b>
                                    <b>{{ $parent.getClassCode(studentClass.id) }}</b>
                                </el-col>
                                <el-col :span="12" style="font-size: 16px">
                                    <b>Effectif de la classe :&nbsp;</b>
                                    <b>{{ classSize }}</b>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
                <div class="text-center" style="font-size: 24px; margin-top: 5px; margin-bottom: 5px">
                    <b>BULLETIN DU {{ yearCutting ? $parent.getYearCuttingSliceLabel(yearCutting.code).toUpperCase() : '' }}</b>
                </div>
                <el-row :gutter="10">
                    <el-col :span="12">
                        <div class="info-box" style="height: 200px">
                            <div style="font-size: 21px">
                                <b class="underline">Vie scolaire</b>
                            </div>
                            <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>Responsable</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 16px">
                                    :&nbsp;<span v-html="schoolLifeManager"></span>
                                </el-col>
                            </el-row>
                            <template v-if="verdict">                        
                                <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                    <el-col :span="8" style="font-size: 16px">
                                        <b>Assiduité de l'élève</b>
                                    </el-col>
                                    <el-col :span="16" style="font-size: 16px"></el-col>
                                </el-row>
                                <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                    <el-col :span="8" style="font-size: 14px">
                                        Nbre d'absences&nbsp;:&nbsp;{{ verdict.absence }}
                                    </el-col>
                                    <el-col :span="8" style="font-size: 14px">
                                        Nbre de retards&nbsp;:&nbsp;{{ verdict.delay }}
                                    </el-col>
                                    <el-col :span="8" style="font-size: 14px">
                                        Non justifiées&nbsp;:&nbsp;{{ verdict.unjustified }}
                                    </el-col>
                                </el-row>
                                <el-row :gutter="10" style="margin-top: 10px; margin-bottom: 10px">
                                    <el-col :span="8" style="font-size: 16px">
                                        <b>Conduite</b>
                                    </el-col>
                                    <el-col :span="16" style="font-size: 16px">
                                        :&nbsp;{{ verdict.conduct }}
                                    </el-col>
                                </el-row>
                            </template>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="info-box" style="height: 200px">
                            <div style="font-size: 21px">
                                <b class="underline">Parents ou Tuteurs</b>
                            </div>
                            <el-row :gutter="10" style="margin-top: 15px; margin-bottom: 15px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>M/Mme</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 16px">
                                    :&nbsp;{{ $parent.getParentName(student.parent) }}
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" style="margin-top: 15px; margin-bottom: 15px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>Tél</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 16px">
                                    :&nbsp;{{ $parent.getParentPhone(student.parent) }}
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" style="margin-top: 15px; margin-bottom: 20px">
                                <el-col :span="8" style="font-size: 16px">
                                    <b>Email</b>
                                </el-col>
                                <el-col :span="16" style="font-size: 16px">
                                    :&nbsp;{{ $parent.getParentEmail(student.parent) }}
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
                <div class="info-box mt-5">
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <div style="font-size: 21px">
                                <b class="underline">Vie extrascolaire</b>
                            </div>
                            <div style="font-size: 16px" class="mt-5 mb-5">
                                <b>Participe aux activités extrascolaires</b>
                            </div>
                            <el-row :gutter="10">
                                <el-col 
                                    :span="6" 
                                    :key="item.id"
                                    style="font-size: 14px" 
                                    v-for="item in $parent.activities.filter(a => a.type == ActivityTypes.EXTRACURRICULAR.id)">
                                    <span class="checkbox-label">{{ item.name }}</span> 
                                    <span class="checkbox" :class="{ 'checked': filteredActivities.find(a => a.activity == item.id) }"></span>
                                </el-col>                            
                            </el-row>
                        </el-col>
                        <el-col :span="12">
                            <div 
                                :key="item.id"
                                style="font-size: 14px"
                                v-for="item in $parent.activities.filter(a => a.type == ActivityTypes.PARENTING.id)">
                                <b class="checkbox-label">{{ item.name }}</b> 
                                <span class="checkbox" :class="{ 'checked': filteredActivities.find(a => a.activity == item.id) }"></span>
                            </div>
                            <div style="font-size: 16px" class="mt-10 mb-5">
                                <b>Participe aux activités récréatives</b>
                            </div>
                            <el-row :gutter="10">
                                <el-col 
                                    :span="8" 
                                    :key="item.id"
                                    style="font-size: 14px" 
                                    v-for="item in $parent.activities.filter(a => a.type == ActivityTypes.RECREATIONAL.id)">
                                    <span class="checkbox-label">{{ item.name }}</span> 
                                    <span class="checkbox" :class="{ 'checked': filteredActivities.find(a => a.activity == item.id) }"></span>
                                </el-col>                            
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </div>                   

            <div class="report-body mt-10 mb-10">
                <first-body-template 
                    :student="student" 
                    :student-class="studentClass"
                    v-if="$parent.getLevelType(studentClass.level) == LevelTypes.COLLEGE.id || $parent.getClassName(studentClass.id).toLowerCase().includes('seconde')"/>
                <second-body-template 
                    :student="student" 
                    :student-class="studentClass"
                    v-else-if="$parent.getClassName(studentClass.id).toLowerCase().includes('premiere') || $parent.getClassName(studentClass.id).toLowerCase().includes('première')"/>
                <third-body-template 
                    :student="student" 
                    :student-class="studentClass"
                    v-else-if="$parent.getClassName(studentClass.id).toLowerCase().includes('terminale')"/>
            </div>

            <div class="report-footer">
                <div class="footer-box mt-5" v-if="verdict">
                    <el-row :gutter="10">
                        <el-col :span="10">
                            <div style="border: 1px solid black; height: 130px; padding: 5px"  class="text-center">
                                <div style="height: 35px">
                                    <b style="font-size: 14px" class="underline">Appréciation globale du Conseil de Classe</b>
                                </div>                        
                                <div style="height: 60px">
                                    <span>{{ verdict.verdict }}</span>
                                </div>
                                <div style="height: 30px">                            
                                    <div style="font-size: 14px">Professeur Principal : {{ $parent.getClassIncumbentName(studentClass.id) }}</div>                                
                                    <div style="font-size: 10px">Tél : (00228) {{ $parent.getClassIncumbentPhone(studentClass.id) }}</div>
                                </div>
                            </div>                    
                        </el-col>
                        <el-col :span="4" style="font-size: 14px">
                            <div class="mb-5">
                                <span class="checkbox mr-10" :class="{ 'checked': verdict.congratulations && parseInt(verdict.congratulations) }"></span>
                                <span class="checkbox-label">Félicitations</span>                                                         
                            </div>
                            <div class="mb-5">
                                <span class="checkbox mr-10" :class="{ 'checked': verdict.compliments && parseInt(verdict.compliments) }"></span>
                                <span class="checkbox-label">Compliments</span>                                                         
                            </div>
                            <div class="mb-5">
                                <span class="checkbox mr-10" :class="{ 'checked': verdict.encouragements && parseInt(verdict.encouragements) }"></span>
                                <span class="checkbox-label">Encouragements</span>                                                         
                            </div>
                            <div class="mb-5">
                                <span class="checkbox mr-10" :class="{ 'checked': verdict.work_alert && parseInt(verdict.work_alert) }"></span>
                                <span class="checkbox-label">Alerte Travail</span>                                                         
                            </div>
                            <div class="mb-5">
                                <span class="checkbox mr-10" :class="{ 'checked': verdict.conduct_alert && parseInt(verdict.conduct_alert) }"></span>
                                <span class="checkbox-label">Alerte Conduite</span>                                                         
                            </div>
                        </el-col>
                        <el-col :span="10" v-if="principalOrDirector" style="padding-left: 10px">
                            <div>
                                Fait à lomé, le {{ formatDate(new Date(), { outPattern: 'DD MMMM YYYY', locale: $i18n.locale }) }}
                            </div>
                            <div class="mt-5"><b>{{ principalOrDirectorTitle }}</b></div>
                            <div style="padding-top: 45px">{{ $parent.getTitleLabel(principalOrDirector.title) + ' ' + principalOrDirector.name }}</div>                            
                        </el-col>
                    </el-row>
                </div>
            </div>                 

        </template>        
    </div>        
    
</template>

<script>
import { StaffRoles, ActivityTypes, LevelTypes, Titles } from '@/utils/enums'
import { APP_BASE_URL } from '@/utils/constants'
import { reformatDate, formatDate } from '@/utils/chronos'

import FirstBodyTemplate from './FirstBodyTemplate'
import SecondBodyTemplate from './SecondBodyTemplate'
import ThirdBodyTemplate from './ThirdBodyTemplate'

import report from '@/mixins/report'

export default {
    name: 'ReportTemplate',
    mixins: [report],
    components:{
        FirstBodyTemplate,
        SecondBodyTemplate,
        ThirdBodyTemplate
    },
    props: {
        student: {
            default: null,
        },
        verdict: {
            default: null,
        },
        classSize: {
            default: 0,
        },
        studentClass: {
            default: null,
        },
        yearCutting: {
            default: null,
        },
        studentActivities: {
            default: [],
        }
    },
    data() {
        return {
            staffRoles: [],
            ActivityTypes,
            LevelTypes,
            APP_BASE_URL
        }
    },
    created() {

        var self = this

        this.$_.forIn(StaffRoles, function(value, key) {
            
            self.staffRoles.push(value)

        })

    },
    computed: {
        schoolyearCode() {

            if (this.$parent.schoolyear) {

                let sy = this.$parent.schoolyears.find(s => s.id == this.$parent.schoolyear)

                return sy ? sy.code : ''

            }

            return ''

        },
        schoolLifeManager() {

            if (this.studentClass) {

                let slm = this.$parent.staff.find(s => s.role == StaffRoles.SCHOOL_LIFE_MANAGER.id && 
                                               s.level == this.studentClass.level && 
                                               s.establishment == this.studentClass.establishment)
                
                if (slm) return slm.name + '<br>&nbsp;&nbsp;<b>Tel&nbsp;:</b>&nbsp;' + slm.contact

                let slm2 = this.$parent.staff.find(s => s.role == StaffRoles.SCHOOL_LIFE_MANAGER.id &&                                                 
                                                s.establishment == this.studentClass.establishment)
                
                if (slm2) return slm2.name + '<br>&nbsp;&nbsp;<b>Tel&nbsp;:</b>&nbsp;' + slm2.contact

            }

            return ''

        },
        principalOrDirectorTitle(){

            if (this.studentClass) {

                let suffix = this.principalOrDirector && this.principalOrDirector.title == Titles.MISS.id ? 'e' : ''
                let prefix = this.principalOrDirector && this.principalOrDirector.title == Titles.MISS.id ? 'La' : 'Le'                

                if (this.$parent.getLevelType(this.studentClass.level) == LevelTypes.LYCEE.id){

                    return `${prefix} Proviseur${suffix}`

                } else {
                    
                    return `${prefix} Principal${suffix}`

                }

            }

            return ''

        },
        principalOrDirector(){

            if (this.studentClass) {

                if (this.$parent.getLevelType(this.studentClass.level) == LevelTypes.LYCEE.id){

                    let slm = this.$parent.staff.find(s => s.role == StaffRoles.DIRECTOR.id && 
                                                   s.level == this.studentClass.level && 
                                                   s.establishment == this.studentClass.establishment)
                
                    if (slm) return slm       

                }else{

                    let slm = this.$parent.staff.find(s => s.role == StaffRoles.PRINCIPAL.id && 
                                                   s.level == this.studentClass.level && 
                                                   s.establishment == this.studentClass.establishment)
                
                    if (slm) return slm

                }                

                return null

            }

        },
        filteredActivities() {

            return this.studentActivities.filter(a => a.value == 1 &&                                                      
                                                      a.class == this.student.class && 
                                                      a.yearcutting == this.yearCutting.id)

        }
    },
    methods: {
        reformatDate,
        formatDate
    }
}
</script>

<style scoped>
    .info-box{
        border: 1px solid black;
        font-size: 21px;
        padding: 14px;
    }

    .standard-height{
        height: 215px;
    }

    .checked{
        background: blue;
    }

    .checkbox{
        width: 15px;
        height: 15px;        
        border: 1px solid black;
        vertical-align: middle;
        display: inline-block;
        border-radius: 4px;
    }

    .checkbox-label{
        margin-right: 5px;
        display: inline-block;        
        vertical-align: middle;
    }

    .underline{
        padding-bottom: 1px; 
        border-bottom: 1px solid black;
    }
</style>