<template>
    
    <div class="tab-header mt-5">
        <el-row>
            <el-col :span="3">
                <div class="text-left">
                    <b>Discipline</b><br>
                    Nom du professeur
                </div>
            </el-col>
            <el-col :span="1">
                Note<br> Classe
            </el-col>
            <el-col :span="1">
                Note<br> Compo
            </el-col>
            <el-col :span="1">
                Moy
            </el-col>
            <el-col :span="1">
                Coef.
            </el-col>
            <el-col :span="1">
                Moy<br> Coef.
            </el-col>
            <el-col :span="2">
                <div style="width: 100%; height: 100%">
                    <div style="font-size: 10px; height: 24px">
                        Notes<br> Extrêmes
                    </div>                    
                    <div style="font-size: 18px; height: 24px; border-top: 1px solid black">
                        <span style="width: 48%; display: inline-block; height: 100%; border-right: 1px solid black" class="text-center">
                            -
                        </span>
                        <span style="width: 48%; display: inline-block; height: 100%" class="text-center">
                            +
                        </span>
                    </div>
                </div>
            </el-col>
            <el-col :span="1">
                Moy<br> Classe
            </el-col>
            <el-col :span="3">
                Attitude face<br> au travail
            </el-col>
            <el-col :span="3">
                Progression
            </el-col>
            <el-col :span="7">
                Conseils
            </el-col>
        </el-row>
    </div>

    <div v-for="(item, i) in currentStudentData.completeItems" :key="i" class="tab-body">

        <template v-if="item">
            
           <template v-if="item.isSepSection">
                <el-row v-if="currentStudentData.completeItems[i + 1]" class="tab-sep-section">
                    <el-col :span="6">
                        {{ item.name }}
                    </el-col>
                    <el-col :span="6">
                        {{ item.name }}
                    </el-col>
                    <el-col :span="6">
                        {{ item.name }}
                    </el-col>
                    <el-col :span="6">
                        {{ item.name }}
                    </el-col>
                </el-row>
            </template>

            <el-row v-else-if="item.isSimpleSection" class="tab-section tab-note-section">
                <el-col :span="3">
                    <div style="line-height: 8px; margin-top: 5px; margin-left: 3px">
                        <b :style="'font-size:' + parent.courseNameFontSize(item.title) + 'px'" style="line-height: 8px">{{ item.title }}</b>
                    </div>
                    <div style="margin-left: 3px">
                        <span :style="'font-size: 14px'">{{ item.subtitle }}</span>
                    </div>                    
                </el-col>
                <el-col :span="1">

                </el-col>
                <el-col :span="1">

                </el-col>
                <el-col :span="1">                    

                </el-col>
                <el-col :span="1">                    

                </el-col>
                <el-col :span="1">                    

                </el-col>
                <el-col :span="2">

                </el-col>
                <el-col :span="1">

                </el-col>
                <el-col :span="3">

                </el-col>
                <el-col :span="3">

                </el-col>
                <el-col :span="7">

                </el-col>
            </el-row>

            <el-row v-else class="tab-section tab-note-section">
                <el-col :span="3">
                    <div style="line-height: 8px; margin-top: 5px; margin-left: 3px">
                        <b :style="'font-size:' + parent.courseNameFontSize(grandparent.getCourseName(item.course)) + 'px'" style="line-height: 8px">{{ grandparent.getCourseName(item.course) }}</b>
                    </div>
                    <div style="margin-left: 3px">
                        <span :style="'font-size: 12px'">{{ grandparent.getJustTeacherName(item.teacher) }}</span>
                    </div>                    
                </el-col>
                <el-col :span="1">

                    <span v-if="item.devNote">{{ item.devNote }}</span>

                </el-col>
                <el-col :span="1">

                    <span v-if="item.compNote">{{ item.compNote }}</span>

                </el-col>
                <el-col :span="1">

                    <span v-if="item.moy">{{ item.moy }}</span>

                </el-col>
                <el-col :span="1">

                    <span v-if="item.coef">{{ item.coef }}</span>

                </el-col>
                <el-col :span="1">

                    <span v-if="item.moyCoef">{{ item.moyCoef }}</span>

                </el-col>
                <el-col :span="2">
                    <el-row style="height: 100%; width: 100%" class="tab-up-down-section">
                        <el-col 
                            :span="12" 
                            style="border: 0; border-right: 1px solid black; display: flex; justify-content: center; align-items: center; text-align: center">
                            <span v-if="item.sMoyDown">{{ item.sMoyDown }}</span>
                        </el-col>
                        <el-col 
                            :span="12" 
                            style="border: 0; display: flex; justify-content: center; align-items: center; text-align: center">
                            <span v-if="item.sMoyUp">{{ item.sMoyUp }}</span>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="1">

                    <span v-if="item.sMoyClass">{{ item.sMoyClass }}</span>

                </el-col>
                <el-col :span="3">

                    <b :style="'font-size: 14px'" v-if="item.attitude">{{ item.attitude }}</b>

                </el-col>
                <el-col :span="3">

                    <b :style="'font-size: 14px'" v-if="item.progression">{{ item.progression }}</b>

                </el-col>
                <el-col :span="7">

                    <b :style="'font-size: 16px'" v-if="item.advice">{{ item.advice }}</b>

                </el-col>
            </el-row>        
            
        </template>
        
    </div>

    <div class="tab-footer">
        <el-row>
            <el-col :span="3" class="left-border bottom-border">
                <div style="margin-left: 3px">
                    <b style="font-size: 13px">{{ parent.currentMoyLabel }}</b>
                </div>                    
            </el-col>
            <el-col :span="1" class="left-border bottom-border">
                <b v-if="currentStudentData.moy">{{ currentStudentData.moy }}</b>
            </el-col>
            <el-col :span="1" class="left-border"></el-col>
            <el-col :span="1" class="left-border bottom-border">
                <span style="font-size: 13px">Total</span>
            </el-col>
            <el-col :span="1" class="left-border bottom-border">
                <b v-if="currentStudentData.totalCoef">{{ currentStudentData.totalCoef }}</b>
            </el-col>
            <el-col :span="1" class="left-border bottom-border">
                <b v-if="currentStudentData.totalMoyCoef">{{ currentStudentData.totalMoyCoef }}</b>
            </el-col>
            <el-col :span="2" class="left-border"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="7"></el-col>
        </el-row>

        <template v-if="grandparent.isYearCuttingTerm">
            <el-row v-if="parent.currentYearCuttingItem && parseInt(parent.currentYearCuttingItem.code) > grandparent.YearTerms.FIRST_TERM.id">
                <el-col :span="3" class="left-border bottom-border">
                    <div style="margin-left: 3px">
                        <b style="font-size: 13px">1er Trimestre</b>
                    </div>                    
                </el-col>
                <el-col :span="1" class="left-border bottom-border">
                    <b v-if="parent.firstTermMoy">{{ parent.firstTermMoy }}</b>
                </el-col>
                <el-col :span="1" class="left-border"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="2"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="3"></el-col>
                <el-col :span="3"></el-col>
                <el-col :span="7"></el-col>
            </el-row>
            <el-row v-if="parent.currentYearCuttingItem && parseInt(parent.currentYearCuttingItem.code) > grandparent.YearTerms.SECOND_TERM.id">
                <el-col :span="3" class="left-border bottom-border">
                    <div style="margin-left: 3px">
                        <b style="font-size: 13px">2ème Trimestre</b>
                    </div>                    
                </el-col>
                <el-col :span="1" class="left-border bottom-border">
                    <b v-if="parent.secondTermMoy">{{ parent.secondTermMoy }}</b>
                </el-col>
                <el-col :span="1" class="left-border"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="2"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="3"></el-col>
                <el-col :span="3"></el-col>
                <el-col :span="7"></el-col>
            </el-row>            
        </template>
        <template v-else>
            <el-row v-if="parent.currentYearCuttingItem && parent.currentYearCuttingItem.code == grandparent.YearSemesters.SECOND_SEMESTER.id">
                <el-col :span="3" class="left-border bottom-border">
                    <div style="margin-left: 3px">
                        <b style="font-size: 13px">1er Semestre</b>
                    </div>                    
                </el-col>
                <el-col :span="1" class="left-border bottom-border">
                    <b v-if="parent.firstSemMoy">{{ parent.firstSemMoy }}</b>
                </el-col>
                <el-col :span="1" class="left-border"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="2"></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="3"></el-col>
                <el-col :span="3"></el-col>
                <el-col :span="7"></el-col>
            </el-row>            
        </template>  
        <el-row v-if="parent.currentYearCuttingItem && (
                      (grandparent.isYearCuttingTerm && parseInt(parent.currentYearCuttingItem.code) > grandparent.YearTerms.SECOND_TERM.id) 
                      || (!grandparent.isYearCuttingTerm && parent.currentYearCuttingItem.code == grandparent.YearSemesters.SECOND_SEMESTER.id))">
            <el-col :span="3" class="left-border bottom-border">
                <div style="margin-left: 3px">
                    <b style="font-size: 13px">Moy. Annuelle</b>
                </div>                    
            </el-col>
            <el-col :span="1" class="left-border bottom-border">
                <b v-if="annualMoy">{{ annualMoy.toFixed(2) }}</b>
            </el-col>
            <el-col :span="1" class="left-border"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="2"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="7"></el-col>
        </el-row>      

        <el-row>
            <el-col :span="3" class="left-border bottom-border">
                <div style="margin-left: 3px">
                    <b style="font-size: 13px">Moy. la + forte</b>
                </div>                    
            </el-col>
            <el-col :span="1" class="left-border bottom-border">
                <b v-if="currentStudentData.moyUp">{{ currentStudentData.moyUp }}</b>
            </el-col>
            <el-col :span="1" class="left-border"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="2"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="7"></el-col>
        </el-row>
        <el-row>
            <el-col :span="3" class="left-border bottom-border">
                <div style="margin-left: 3px">
                    <b style="font-size: 13px">Moy. la + faible</b>
                </div>                    
            </el-col>
            <el-col :span="1" class="left-border bottom-border">
                <b v-if="currentStudentData.moyDown">{{ currentStudentData.moyDown }}</b>
            </el-col>
            <el-col :span="1" class="left-border"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="2"></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="3"></el-col>
            <el-col :span="7"></el-col>
        </el-row>
    </div>

</template>

<script>
import { EvaluationTypes } from '@/utils/enums'

export default {
    name: 'FirstBodyTemplate',
    props: {
        student: {
            default: null,
        },
        studentClass: {
            default: null,
        },
    },
    data() {
        return {
            grandparent: this.$parent.$parent,
            parent: this.$parent
        }
    },
    computed: {                
        reportBodyCourses(){

            let items = [], self = this

            if (this.parent.reportBodySections){

                this.parent.reportBodySections.forEach((section, i) => {
                    
                    if (section){
                        
                        let sectionCopy = this.$_.cloneDeep(section)

                        sectionCopy.isSepSection = true

                        items.push(sectionCopy)

                        items.push(...self.parent.sortedRepartitionList.filter(element => element.section == section.id))

                    }
                
                })

            }

            return items

        },
        reportBodyPartialNotes() {

            let studentsCompleteItems = []

            this.grandparent.tableData.forEach(async (student) => {
              
              let completeItems = []
              
              let notes = this.grandparent.evaluationNotes.filter(note => (
                  
                  note.student == student.id && 
                  note.class == this.studentClass.id &&
                  note.yearcutting == this.grandparent.currentYearCutting

              ))

              let texts = this.grandparent.evaluationTexts.filter(text => (

                  text.student == student.id && 
                  text.class == this.studentClass.id &&
                  text.yearcutting == this.grandparent.currentYearCutting

              ))

              this.reportBodyCourses.forEach(item => {
                  
                  if (!(item.isSepSection || item.isSimpleSection)){                    
                    
                    let completeItem = this.$_.cloneDeep(item)

                    let devNote = notes.find(note => note.course == item.course && note.teacher == item.teacher && this.parent.getEvaluationTypeTypeFromEvaluation(note.evaluation) == EvaluationTypes.ASSIGNMENT.id),
                        compNote = notes.find(note => note.course == item.course && note.teacher == item.teacher && this.parent.getEvaluationTypeTypeFromEvaluation(note.evaluation) == EvaluationTypes.COMPOSITION.id)

                    let isDevNoteValid = devNote ? !Number.isNaN(parseFloat(devNote.note)) : false,
                        isCompNoteValid = compNote ? !Number.isNaN(parseFloat(compNote.note)) : false

                    if (isDevNoteValid) completeItem.devNote = parseFloat(devNote.note)
                    if (isCompNoteValid) completeItem.compNote = parseFloat(compNote.note)

                    if ( isDevNoteValid || isCompNoteValid ){

                        if (isDevNoteValid && isCompNoteValid) {

                            completeItem.moy = (completeItem.devNote + completeItem.compNote) / 2

                        }else if (isDevNoteValid) {

                            completeItem.moy = completeItem.devNote

                        }else if (isCompNoteValid) {

                            completeItem.moy = completeItem.compNote

                        }

                        completeItem.moyCoef = completeItem.moy * parseInt(completeItem.coef)

                        let text = texts.find(text => text.course == item.course && text.teacher == item.teacher)

                        if (text){
                            completeItem.attitude = text.attitude
                            completeItem.progression = text.progression
                            completeItem.advice = text.advice
                        }

                        completeItems.push(completeItem)

                    }                    
                    
                  } else {
                    
                    completeItems.push(item)

                  }                  

              })

              let totalCoef = 0, totalMoy = 0, totalMoyCoef = 0, moy = 0, nb = 0

              completeItems.forEach(completeItem => {
                  if (!(completeItem.isSepSection || completeItem.isSimpleSection)){
                    nb += 1
                    totalMoy += parseFloat(completeItem.moy)
                    totalCoef += parseFloat(completeItem.coef)
                    totalMoyCoef += parseFloat(completeItem.moyCoef)
                  }
              })

              moy = totalMoyCoef / totalCoef

              studentsCompleteItems.push({                    
                  moy: moy.toFixed(2),
                  student: student,
                  totalMoy: totalMoy.toFixed(2),
                  totalCoef: totalCoef, 
                  totalMoyCoef: totalMoyCoef.toFixed(2),
                  completeItems: completeItems, 
              })

              await this.grandparent.fetchYearCuttingsDB({ 
                  action: 'updateYearCuttingResult',
                  params: {
                      yearcutting: this.parent.currentYearCuttingItem.id,
                      code: this.parent.currentYearCuttingItem.code,
                      student: student.id,
                      average: moy.toFixed(2),
                      schoolyear: this.adminSchoolyear
                  } 
              })
              
            })

            return studentsCompleteItems

        },
        reportBodyFullNotes(){

            let finalNotes = [], moyList = []

            this.reportBodyPartialNotes.forEach(partialNote => {
                if (!Number.isNaN(parseFloat(partialNote.moy))) moyList.push(partialNote.moy)                
            })

            for (let i = 0; i < this.reportBodyPartialNotes.length; i++) {
                
                let finalPartialNote = this.$_.cloneDeep(this.reportBodyPartialNotes[i])

                for (let y = 0; y < this.reportBodyPartialNotes[i].completeItems.length; y++) {

                    let completeItem = this.$_.cloneDeep(this.reportBodyPartialNotes[i].completeItems[y])
                    
                    if (!(completeItem.isSepSection || completeItem.isSimpleSection)){
                        
                        let course = completeItem.course

                        let moys = [], totalMoys = 0

                        this.reportBodyPartialNotes.forEach(pn => {
                            pn.completeItems.forEach(ci => {
                                if (!(ci.isSepSection || ci.isSimpleSection)){
                                    if (ci.course == course && !Number.isNaN(parseFloat(ci.moy))) {
                                        
                                        moys.push(ci.moy)
                                        
                                        totalMoys += parseFloat(ci.moy)

                                    }
                                }    
                            })
                        })

                        if (moys.length){
                            finalPartialNote.completeItems[y].sMoyClass = (totalMoys / moys.length).toFixed(2)

                            finalPartialNote.completeItems[y].sMoyUp = Math.max.apply(null, moys)
                            finalPartialNote.completeItems[y].sMoyDown = Math.min.apply(null, moys)
                        }

                    }

                }

                finalPartialNote.moyUp = Math.max.apply(null, moyList)
                finalPartialNote.moyDown = Math.min.apply(null, moyList)

                finalNotes.push(finalPartialNote)

            }

            return finalNotes

        },
        currentStudentData(){

            let notes = this.reportBodyFullNotes.find(data => data.student.id == this.student.id)

            return notes ? notes : this.reportBodyCourses

        },
        annualMoy(){
            if (this.grandparent.isYearCuttingTerm){
                
                if (this.parent.firstTermMoy && this.parent.secondTermMoy && this.currentStudentData.moy){
                    return (parseFloat(this.parent.firstTermMoy) + parseFloat(this.parent.secondTermMoy) + parseFloat(this.currentStudentData.moy)) / 3
                }

            }else{
                
                if (this.parent.firstSemMoy && this.currentStudentData.moy){
                    return (parseFloat(this.parent.firstSemMoy) + parseFloat(this.currentStudentData.moy)) / 2
                }

            }

            return 0
        }
    }
}
</script>

<style scoped>
    .tab-header .el-col {
        height: 50px;
        font-size: 12px;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
    }

    .tab-header .el-row:last-child {
        border-right: 1px solid black;
    }

    .tab-section .el-col {
        border-left: 1px solid black;        
        border-bottom: 1px solid black;
    }

    .tab-section .el-col:last-child, .tab-sep-section .el-col:last-child {
        border-right: 1px solid black;
    }

    .tab-section .el-col:not(:first-child):not(:last-child):not(:nth-child(9)):not(:nth-child(10)),
    .tab-footer .el-col:not(:first-child):not(:last-child) {
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 20px;
        font-size: 16px;
        display: flex;
    }

    .tab-footer .el-col:nth-child(6){
        font-size: 13px !important;
    }

    .tab-section .el-col:last-child, 
    .tab-section .el-col:nth-child(9),
    .tab-section .el-col:nth-child(10) {
        justify-content: left;
        align-items: center;
        text-align: left;
        min-height: 20px;
        font-size: 14px;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
    }

    .tab-section .el-col:first-child{
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
    }

    .tab-up-down-section .el-col{
        font-size: 16px !important;
    }

    .tab-note-section.el-row {
        min-height: 75px;
    }

    .tab-footer .el-row{
        min-height: 30px;
    }

    .tab-footer .el-col:first-child{
        justify-content: left;
        align-items: center;
        text-align: left;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
    }

    .tab-sep-section .el-col:first-child {
        border-left: 1px solid black;
        padding-left: 3px
    }

    .tab-sep-section .el-col {
        border-bottom: 1px solid black;
        font-weight: bold;
        padding-top: 3px;
        font-size: 13px;
    }

    .top-border{
        border-top: 1px solid black;
    }

    .bottom-border {
        border-bottom: 1px solid black;
    }

    .right-border {
        border-right: 1px solid black;
    }

    .left-border {
        border-left: 1px solid black;
    }
</style>